import React from "react";
// import insta from "../assets/insta.svg";
// import twitter from "../assets/twitter.svg";
// import github from "../assets/github.svg";
import linkedin from "../assets/linkedin.svg";

const TeamCard = ({ name, role, image, content }) => {
  return (
    <div
      role="listitem"
      className="xl:w-1/3 sm:w-3/4 md:w-2/5 lg:w-2/5 py-14 rounded-xl "
      style={{
        background: `radial-gradient(ellipse at bottom, #7856F7,#01000E )`,
      }}
    >
      <div className="rounded overflow-hidden  flex flex-col gap-5">
        {/* <div className=" w-full flex justify-center">
          <div className="h-32 w-32">
            <img
              src={image}
              alt={`Display of ${name}`}
              className="rounded-full object-cover h-full w-full shadow-md "
            />
          </div>
        </div> */}
        <div className="px-6 flex flex-col gap-4 ">
          <h1 className="font-bold dark:text-white text-3xl text-center mb-1">
            {name}
          </h1>
          <p className=" dark:text-white text-sm text-center">{role}</p>
          <p className="text-center  dark:text-gray-200 text-base font-normal my-2">
            {content}
          </p>
          <div className="flex justify-center mt-4 mb-2">
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-5 hover:scale-110"
            >
              <div aria-label="Linkedin" role="img">
                <img src={linkedin} alt="github" className="w-10 h-10" />
              </div>
            </a>
            {/* <a href="https://twitter.com/home" className="mx-5 ">
              <div aria-label="Twitter" role="img">
                <img src={twitter} alt="twitter" className="w-10 h-10 " />
              </div>
            </a>
            <a href="https://instagram.com/" className="mx-5">
              <div aria-label="Instagram" role="img">
                <img src={insta} alt="instagram" className="w-10 h-10" />
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
