import React from "react";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import HeaderButtonAnimation from "../components/ui/HeaderButtonAnimation";
import { useSnackbar } from "notistack";

const ContactSection = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!firstName || !lastName || !email || !message) {
      enqueueSnackbar("Veuillez remplir tous les champs", {
        variant: "error",
      });
      return;
    }

    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setMessage(message);

    emailjs
      .sendForm("service_vzduv6z", "template_ppd17jd", form.current, {
        publicKey: "f03TqMDFFT-PRV1kd",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          enqueueSnackbar(
            "Message envoyé avec succès! Nous reviendrons vers vous bientôt",
            {
              variant: "success",
            }
          );
        },
        (error) => {
          console.log("FAILED...", error.text);
          enqueueSnackbar("Échec de l'envoi du message. Essayer à nouveau!", {
            variant: "error",
          });
        }
      );
  };

  return (
    <div className="container px-6 py-10 mx-auto text-white" id="contact">
      <div className="">
        <p className=" text-[#7856F7] font-semibold text-lg md:text-xl">
          Contactez-nous
        </p>
        <h1 className="mt-2 text-3xl md:text-5xl font-semibold text-white  dark:text-white">
          Discutez avec notre équipe
        </h1>
        <p className="mt-3 text-gray-500 dark:text-gray-400">
          Pour nous contacter, veuillez remplir ce formulaire ou envoyez-nous un e-mail
        </p>
      </div>
      <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
          <div>
            <span className="inline-block p-3 text-[#7856F7] rounded-full bg-blue-100/80 dark:bg-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </span>
            <h2 className="mt-4 text-base font-medium text-white dark:text-white">
              Email
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Notre équipe est là pour vous aider.
            </p>
            <a
              href="mailto:teamoblatio@outlook.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <p className="mt-2 text-sm text-[#7856F7]">
                teamoblatio@outlook.com
              </p>
            </a>
          </div>

          <div>
            <span className="inline-block p-3 text-[#7856F7] rounded-full bg-blue-100/80 dark:bg-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
            </span>

            <h2 className="mt-4 text-base font-medium text-white dark:text-white">
              Bureau
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Venez dire bonjour à notre siège social.
            </p>
            <p className="mt-2 text-sm text-[#7856F7] ">
              66 AVENUE DES CHAMPS ELYSEES, 75008 PARIS 8
            </p>
          </div>

          <div>
            <span className="inline-block p-3 text-[#7856F7] rounded-full bg-blue-100/80 dark:bg-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </span>

            <h2 className="mt-4 text-base font-medium text-white dark:text-white">
              Téléphone
            </h2>

            <p className="mt-2 text-sm text-[#7856F7] ">+33769060478</p>
          </div>
        </div>
        <div className="py-16 px-8 rounded-lg  text-white border">
          <form ref={form} onSubmit={handleSubmit}>
            <div className="-mx-2 md:items-center md:flex">
              <div className="flex-1 px-2">
                <label className="block mb-2 text-left text-sm ">Prénom</label>
                <input
                  type="text"
                  placeholder="John "
                  className="block w-full px-5 py-2.5 mt-2  placeholder-gray-400 bg-transparent border-b border-gray-200 focus:border-blue-400 rounded  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="flex-1 px-2 mt-4 md:mt-0">
                <label className="block mb-2 text-left text-sm  dark:text-gray-200">
                  Nom de famille
                </label>
                <input
                  type="text"
                  placeholder="Doe"
                  className="block w-full px-5 py-2.5 mt-2  placeholder-gray-400 bg-transparent border-b border-gray-200 focus:border-blue-400 rounded  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="block mb-2 text-left text-sm  dark:text-gray-200">
                Adresse e-mail
              </label>
              <input
                type="email"
                placeholder="johndoe@example.com"
                className="block w-full px-5 py-2.5 mt-2  placeholder-gray-400 bg-transparent border-b border-gray-200 focus:border-blue-400 rounded  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="w-full mt-4">
              <label className="block mb-2  text-left text-sm  dark:text-gray-200">
                Message
              </label>
              <textarea
                className="block w-full h-40 px-5 py-2.5 mt-2 placeholder-gray-400 bg-transparent border-b border-gray-200 focus:border-blue-400 rounded focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Message"
                value={message}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            <div className="flex justify-end mt-6">
              <HeaderButtonAnimation
                containerClassName="rounded-xl"
                as="button"
                type="submit"
                className="bg-gradient-to-tr from-[#7856F7] via-transparent to-transparent hover:bg-[#7856F7] hover:from-[#7856F7] hover:[#7856F7] text-white font-bold py-3 px-4 rounded transform transition  "
              >
                {" "}
                Envoyer le message
              </HeaderButtonAnimation>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
