import React from "react";
import TeamCard from "./TeamCard";

const OurTeam = () => {
  return (
    <>
      <div
        className="container flex justify-center mx-auto pt-16 text-white"
        id="team"
      >
        <div>
          <p className="text-lg md:text-xl text-center font-normal pb-3 text-white/70">
            CONSTRUIRE UNE ÉQUIPE
          </p>
          <h1 className="text-3xl md:text-4xl text-center font-extrabold pb-6 ">
            Les personnes talentueuses dans les coulisses de l’organisation
          </h1>
        </div>
      </div>
      <div className=" text-white px-10 pt-10">
        <div className="container mx-auto">
          <div
            role="list"
            aria-label="Behind the scenes People"
            className="flex flex-col md:flex-row gap-8 md:gap-0 items-center flex-wrap justify-around "
          >
            <TeamCard
              name="Titouan Prioux"
              role="Co-fondateurs"
              image="https://cdn.tuk.dev/assets/photo-1564061170517-d3907caa96ea.jfif"
              content="Il pilote notre équipe de développement dans la création de solutions technologiques innovantes."
            />
            <TeamCard
              name="Aymane Haitoune"
              role="Co-fondateurs"
              image="https://cdn.tuk.dev/assets/photo-1564061170517-d3907caa96ea.jfif"
              content="Il identifie les opportunités de marché, élabore des stratégies de croissance et forge des partenariats pour étendre notre influence dans le domaine du SaaS."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
