import React from "react";
import Hero from "../components/Hero";
import OurTeam from "../components/OurTeam";
// import StatsSection from "./StatsSection";
import ContactSection from "./Contact";
import EarlyAccess from "../components/EarlyAccess";
import AboutUs from "../components/AboutUs";
import Container from "../components/Container";

const Home = () => {
  return (
    <div className=" flex flex-col gap-10 lg:gap-14">
      <Hero />
      <Container>
        <AboutUs />
      </Container>
      {/* <Container>
        <StatsSection />
      </Container> */}
      <Container>
        <OurTeam />
      </Container>
      <Container>
        <ContactSection />
      </Container>
      <Container>
        <EarlyAccess />
      </Container>
    </div>
  );
};

export default Home;
