import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import { Header } from "./pages/Header";
import NotFound from "./pages/NotFound";
import Container from "./components/Container";
import Privacy from "./pages/Privacy";
import TermsofService from "./pages/TermsofService";

function App() {
  return (
    <>
      <Container>
        <Header />
      </Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/privacy"
          element={
            <Container>
              <Privacy />{" "}
            </Container>
          }
        />
        <Route
          path="/terms"
          element={
            <Container>
              <TermsofService />{" "}
            </Container>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default App;
