import React, { useState } from "react";
import BackgroundGradientAnimation from "./ui/BackgroundGradientAnimation";
import HeaderButtonAnimation from "../components/ui/HeaderButtonAnimation";
import hero from "../assets/hero-image.png";
import { useSnackbar } from "notistack";

const Hero = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleEarlyAccess = async (e) => {
    e.preventDefault();
    if (email) {
      const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === "" || !validEmail.test(email)) {
        enqueueSnackbar("Veuillez saisir une adresse e-mail valide", {
          variant: "error",
        });
        return;
      }
      const response = await fetch(
        "https://discord.com/api/webhooks/1224756071155896351/UX1iqgy7ZvTIYKOy9bgzMZ7ThFzTTz7rb9YZuvZLtscJgNXzcKrQmVi0x37l747iAMbt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            embeds: [
              {
                title: "Inscription à l'accès anticipé",
                description: `Email: ${email}`,
                color: 0x7856f7,
              },
            ],
          }),
        }
      );

      if (response.ok) {
        enqueueSnackbar("Merci de vous être inscrit pour un accès anticipé !", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Échec de l'inscription à l'accès anticipé", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("S'il vous plaît, mettez une adresse email valide", {
        variant: "error",
      });
    }

    setEmail("");
  };

  return (
    <BackgroundGradientAnimation className="my-20 lg:my-32 ">
      <div className="flex flex-wrap flex-col lg:flex-row items-center text-white z-50 px-6 lg:px-0 lg:mx-20">
        <div className="flex flex-col w-full lg:w-1/2 items-center overflow-y-hidden">
          <h1 className="my-4 text-3xl md:text-5xl text-white opacity-75 font-aeonik leading-tight text-center md:text-left">
            Façonnez l'avenir&nbsp;
            <span
              className="bg-clip-text text-transparent bg-gradient-to-r from-[#7856F7]  via-[#E053FF]  to-[#FCE26D] "
              style={{
                WebkitBackgroundClip: "text",
              }}
            >
              de votre carrière&nbsp;
            </span>
            avec OblatIO
          </h1>
          <p className="leading-normal text-lg lg:text-2xl mb-8 text-center md:text-left text-white/50">
            Naviguez parmi les opportunités enrichies par l'IA pour trouver le
            stage ou l'alternance qui vous correspond.
          </p>

          <form className="opacity-75 w-full rounded-lg pt-6 pb-8 mb-0 lg:mb-4 z-50">
            <div className="mb-4">
              <label className="block text-lg mb-2" htmlFor="emailaddress">
                Inscrivez-vous pour un accès anticipé
              </label>
              <div className="flex items-center justifgy-center gap-2 md:gap-4 pt-4 cursor-pointer mb-4">
                <input
                  className="bg-transparent appearance-none border border-gray-100/50 rounded-xl py-3 px-2 ml-2 w-[70%] sm:w-3/5 leading-tight focus:ring transform transition"
                  id="emailaddress"
                  type="text"
                  placeholder="Adresse e-mail"
                  value={email}
                  name="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="md:text-base w-[30%] sm:w-2/5">
                  <HeaderButtonAnimation
                    onClick={handleEarlyAccess}
                    containerClassName="rounded-xl"
                    as="button"
                    className="bg-gradient-to-tr from-[#7856F7] via-transparent to-transparent hover:bg-[#7856F7] hover:from-[#7856F7] hover:[#7856F7] text-white font-bold py-1 lg:py-3 px-2 lg:px-4 rounded transform transition text-sm "
                  >
                    S'inscrire
                  </HeaderButtonAnimation>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="w-full lg:w-1/2 overflow-hidden z-20 cursor-pointer">
          <img
            className="mx-auto w-full sm:w-auto lg:w-2/3 transform -rotate-6 transition hover:scale-105 duration-700 ease-in-out hover:rotate-6"
            src={hero}
            alt="Hero icon"
          />
        </div>
      </div>
    </BackgroundGradientAnimation>
  );
};

export default Hero;
