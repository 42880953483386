import React from "react";
import HeaderButtonAnimation from "../components/ui/HeaderButtonAnimation";
import { HashLink } from "react-router-hash-link";
import OblatIO from "../assets/OblatIO.svg";

const navItems = [
  { name: "Home", link: "/#hero" },
  { name: "À propos", link: "/#about" },
  { name: "Notre équipe", link: "/#team" },
  { name: "Contactez-nous", link: "/#contact" },
];

export const Header = () => {
  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center my-4 mx-10 lg:mx-20">
      <a
        className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
        href="/"
      >
        <img src={OblatIO} alt="OblatIO" className="h-24 w-24" />
      </a>

      <div className="hidden sm:block  max-w-fit items-center space-x-2 sm:space-x-5 md:space-x-7 rounded-full text-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] z-50 py-1 px-4 lg:py-3 lg:px-10 mx-auto border border-white/50 bg-black/50 backdrop-blur-md ">
        {navItems?.map((navItem, idx) => (
          <HashLink
            key={`link-${idx}`}
            to={navItem.link}
            className="text-white  hover:text-neutral-500"
            smooth
          >
            <span className="text-xs sm:text-sm md:text-base lg:text-lg ">
              {navItem.name}
            </span>
          </HashLink>
        ))}
      </div>

      <div>
        <HeaderButtonAnimation
          containerClassName="rounded-full"
          as="button"
          className="text-xs sm:text-sm md:text-base lg:text-xl font-medium bg-black  dark:text-white flex px-4 lg:px-8 py-1 lg:py-2"
        >
          <HashLink to="/#early" smooth>
            {" "}
            Adhérer maintenant{" "}
          </HashLink>
        </HeaderButtonAnimation>
      </div>
    </div>
  );
};
