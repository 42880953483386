import React from "react";
import aboutUs from "../assets/about-us.png";

const AboutUs = () => {
  return (
    <div className="md:px-6 px-4 text-white" id="about">
      <div className="2xl:mt-10 flex lg:flex-row justify-between flex-col lg:gap-10 gap-12">
        <div className="w-full xl:w-5/12 lg:w-6/12">
          <p className="text-lg md:text-xl leading-3 text-[#7856F7] font-semibold cursor-pointer mb-4">
            À propos
          </p>
          <h2 className="font-bold text-3xl md:text-5xl lg:leading-9 leading-7 mb-8 md:mb-14 ">
            À propos de nous
          </h2>
          <p className=" text-lg leading-6 text-white/80 mt-4">
            OblatIO révolutionne la manière dont les talents rencontrent les
            opportunités. Grâce à notre plateforme innovante alimentée par
            l'intelligence artificielle, nous facilitons une mise en relation
            précise et personnalisée entre candidats et offres de stages ou
            d'alternances. Découvrez bientôt comment révolutionner votre
            recherche d'opportunités.
          </p>
          {/* <p className=" text-lg leading-6 text-white/80 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt ipsum
            beatae ducimus quam, sit at sequi quo saepe sint magnam, dolor rerum
            corporis reiciendis. Nihil magnam iusto est deserunt consequatur.
          </p> */}
        </div>
        <div className="lg:flex items-center w-full lg:w-1/2">
          <img
            className="lg:block hidden w-full"
            src={aboutUs}
            alt="people discussing on board"
          />
          <img
            className="lg:hidden sm:block hidden w-full h-3/4"
            src={aboutUs}
            alt="people discussing on board"
          />
          <img
            className="sm:hidden block w-full"
            src={aboutUs}
            alt="people discussing on board"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
