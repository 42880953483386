import React from "react";

const TermsofService = () => {
  return (
    <div className="flex flex-col gap-5 px-4 my-16 py-10 text-white ">
      <h1 className="text-2xl mt-10 text-primary-blue align-center">
        Terms of Service
      </h1>
      <p>
        By using our website, you agree to the Terms of Service. We reserve the
        right to change the terms of service at any time. You are responsible
        for reviewing the terms of service on a regular basis. Accessing our
        website after any changes to the terms of service are posted constitutes
        your acceptance of the new terms of service. Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Sapiente voluptate autem velit, laboriosam
        in repellendus reiciendis aliquam quisquam expedita amet doloremque
        debitis placeat asperiores corrupti ex rem, saepe ab Lorem ipsum dolor
        sit amet consectetur adipisicing elit. Odio beatae est expedita,
        possimus deserunt laudantium nisi commodi excepturi consectetur voluptas
        neque velit ea maiores unde repellendus minima. Qui, ducimus cupiditate?
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
        laboriosam cupiditate ratione accusantium maiores voluptas facilis ea?
        Unde optio sequi ullam praesentium voluptas? Repudiandae dolorum,
        sapiente quasi iure velit alias! By using our website, you agree to the
        Terms of Service. We reserve the right to change the terms of service at
        any time. You are responsible for reviewing the terms of service on a
        regular basis. Accessing our website after any changes to the terms of
        service are posted constitutes your acceptance of the new terms of
        service. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sapiente voluptate autem velit, laboriosam in repellendus reiciendis
        aliquam quisquam expedita amet doloremque debitis placeat asperiores
        corrupti ex rem, saepe ab Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Odio beatae est expedita, possimus deserunt laudantium
        nisi commodi excepturi consectetur voluptas neque velit ea maiores unde
        repellendus minima. Qui, ducimus cupiditate? Lorem ipsum dolor sit amet,
        consectetur adipisicing elit. Atque laboriosam cupiditate ratione
        accusantium maiores voluptas facilis ea? Unde optio sequi ullam
        praesentium voluptas? Repudiandae dolorum, sapiente quasi iure velit
        alias! By using our website, you agree to the Terms of Service. We
        reserve the right to change the terms of service at any time. You are
        responsible for reviewing the terms of service on a regular basis.
        Accessing our website after any changes to the terms of service are
        posted constitutes your acceptance of the new terms of service. Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Sapiente voluptate
        autem velit, laboriosam in repellendus reiciendis aliquam quisquam
        expedita amet doloremque debitis placeat asperiores corrupti ex rem,
        saepe ab Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
        beatae est expedita, possimus deserunt laudantium nisi commodi excepturi
        consectetur voluptas neque velit ea maiores unde repellendus minima.
        Qui, ducimus cupiditate? Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Atque laboriosam cupiditate ratione accusantium
        maiores voluptas facilis ea? Unde optio sequi ullam praesentium
        voluptas? Repudiandae dolorum, sapiente quasi iure velit alias! By using
        our website, you agree to the Terms of Service. We reserve the right to
        change the terms of service at any time. You are responsible for
        reviewing the terms of service on a regular basis. Accessing our website
        after any changes to the terms of service are posted constitutes your
        acceptance of the new terms of service. Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Sapiente voluptate autem velit, laboriosam
        in repellendus reiciendis aliquam quisquam expedita amet doloremque
        debitis placeat asperiores corrupti ex rem, saepe ab Lorem ipsum dolor
        sit amet consectetur adipisicing elit. Odio beatae est expedita,
        possimus deserunt laudantium nisi commodi excepturi consectetur voluptas
        neque velit ea maiores unde repellendus minima. Qui, ducimus cupiditate?
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
        laboriosam cupiditate ratione accusantium maiores voluptas facilis ea?
        Unde optio sequi ullam praesentium voluptas? Repudiandae dolorum,
        sapiente quasi iure velit alias!
      </p>
    </div>
  );
};

export default TermsofService;
