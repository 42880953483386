import React, { useState } from "react";
import { useSnackbar } from "notistack";
import HeaderButtonAnimation from "./ui/HeaderButtonAnimation";

const EarlyAccess = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleEarlyAccess = async (e) => {
    e.preventDefault();
    if (email) {
      const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === "" || !validEmail.test(email)) {
        enqueueSnackbar("Veuillez saisir une adresse e-mail valide", {
          variant: "error",
        });
        return;
      }
      const response = await fetch(
        "https://discord.com/api/webhooks/1224756071155896351/UX1iqgy7ZvTIYKOy9bgzMZ7ThFzTTz7rb9YZuvZLtscJgNXzcKrQmVi0x37l747iAMbt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            embeds: [
              {
                title: "Inscription à l'accès anticipé",
                description: `Email: ${email}`,
                color: 0x7856f7,
              },
            ],
          }),
        }
      );

      if (response.ok) {
        enqueueSnackbar("Merci de vous être inscrit pour un accès anticipé !", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Échec de l'inscription à l'accès anticipé", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("S'il vous plaît, mettez une adresse email valide", {
        variant: "error",
      });
    }

    setEmail("");
  };

  return (
    <div
      className="mb-20 relative flex flex-col items-center justify-center text-white"
      id="early"
    >
      <div className="max-w-2xl mx-auto px-4 flex flex-col gap-5 ">
        <h1 className="pb-6 relative z-30 text-2xl sm:text-4xl md:text-7xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-[#7856F7]  text-center font-sans font-bold">
          Obtenez un accès anticipé
        </h1>
        <p className="mt-4  mx-auto text-base text-center relative z-10">
          Accédez en avant-première à OblatIO et forgez votre parcours
          professionnel avec l'IA.
        </p>

        <form className="opacity-75 w-full rounded-lg mx-auto text-center px-6 pt-6 z-50">
          <div className="flex items-center justifgy-center gap-2 md:gap-4 pt-4 cursor-pointer mb-4">
            <input
              className="bg-transparent appearance-none border border-gray-100/50 rounded-xl py-3 px-2  w-[65%] sm:w-3/5 leading-tight focus:ring transform transition "
              id="emailaddress"
              type="text"
              placeholder="Adresse e-mail"
              value={email}
              name="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="md:text-base w-[30%] sm:w-2/5">
              <HeaderButtonAnimation
                onClick={handleEarlyAccess}
                containerClassName="rounded-xl"
                as="button"
                className="bg-gradient-to-tr from-[#7856F7] via-transparent to-transparent hover:bg-[#7856F7] hover:from-[#7856F7] hover:[#7856F7] text-white font-bold py-1 lg:py-3 px-2 lg:px-4 rounded transform transition text-sm "
              >
                S'inscrire
              </HeaderButtonAnimation>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EarlyAccess;
